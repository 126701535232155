<template>
  <v-container>
    <v-row class="pb-10 grey--text text--darken-1 display-1">
      <v-col cols="12" md="5"> {{ $t("meeting_table.info") }}</v-col
      ><v-col cols="12" md="5">
        <v-btn color="primary" dark @click="newMeeting()">
          {{ $t("meeting_table.new") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-alert v-show="errorAxios" class="mb-6" type="error">
      {{ $t("meeting_table.error_connection") }}
    </v-alert>

    <v-dialog v-model="loading" fullscreen>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.4)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular
            indeterminate
            :size="60"
            :width="7"
            color="primary"
          >
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="meetings"
      item-key="id"
      class="elevation-1"
      @click:row="clickTableRow"
      :search="search"
      :custom-filter="filterText"
    >
      <!-- <template v-slot:[`item.topic`]="{ value }">
        <div class="frame" style="height: 50px; overflow-y: scroll">
          <div :inner-html.prop="value"></div>
        </div>
      </template> -->
      <template v-slot:top>
        <v-row class="text-right">
          <v-col cols="12" md="5">
            <v-text-field
              v-model="search"
              prepend-icon="mdi-find-replace"
              :label="$t('meeting_table.search')"
              class="ma-0 ml-md-4"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="5">
            <v-combobox
              clearable
              prepend-icon="mdi-find-replace"
              v-model="selectWhoProcessed"
              :items="itemWhoProcessed"
              :label="$t('meeting_table.filter_who_processed')"
              multiple
              chips
              class="ma-0 mr-md-4"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="5">
            <v-combobox
              clearable
              prepend-icon="mdi-find-replace"
              v-model="selectOvm"
              :items="itemOvm"
              :label="$t('meeting_table.filter_ovm')"
              multiple
              chips
              class="ma-0 ml-md-4"
            ></v-combobox>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="5">
            <v-combobox
              clearable
              prepend-icon="mdi-find-replace"
              v-model="selectWhoApproved"
              :items="itemWhoApproved"
              :label="$t('meeting_table.filter_who_approved')"
              multiple
              chips
              class="ma-0 mr-md-4"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="5">
            <v-combobox
              clearable
              prepend-icon="mdi-find-replace"
              v-model="selectParticipant"
              :items="itemParticipant"
              :label="$t('meeting_table.filter_participant')"
              multiple
              chips
              class="ma-0 ml-md-4"
            ></v-combobox>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="5">
            <v-combobox
              clearable
              prepend-icon="mdi-find-replace"
              v-model="selectVersion"
              :items="itemVersion"
              :label="$t('meeting_table.filter_version')"
              multiple
              chips
              class="ma-0 mr-md-4"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="5">
            <v-combobox
              clearable
              prepend-icon="mdi-find-replace"
              v-model="selectUID"
              :items="itemUID"
              :label="$t('meeting_table.filter_UID')"
              multiple
              chips
              class="ma-0 ml-md-4"
            ></v-combobox>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="5">
            <v-combobox
              clearable
              prepend-icon="mdi-find-replace"
              v-model="selectProject"
              :items="itemProject"
              :label="$t('meeting_table.filter_project')"
              chips
              class="ma-0 mr-md-4"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row class="ma-0 ml-md-1">
          <v-col cols="12" sm="6" md="3">
            <v-menu
              ref="menuDateBegin"
              v-model="menuDateBegin"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateBegin"
                  :label="$t('meeting_table.date_begin')"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  @click:clear="dateBegin = null"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                v-model="dateBegin"
                locale="sk-SK"
                no-title
                scrollable
                @change="menuDateBegin = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-menu
              ref="menuDateEnd"
              v-model="menuDateEnd"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateEnd"
                  :label="$t('meeting_table.date_end')"
                  prepend-icon="mdi-calendar"
                  clearable
                  @click:clear="dateEnd = null"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                v-model="dateEnd"
                locale="sk-SK"
                no-title
                scrollable
                @change="menuDateEnd = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="6" class="text-center">
            <download-excel
              class="btn"
              :fetch="downloadAllData"
              :fields="json_fields"
              worksheet="My Worksheet"
              name="vsetky_meetingy.xls"
            >
              <v-btn color="primary" class="ma-0 mr-md-5 ma-0 mt-md-5">
                {{ $t("meeting_table.download_all_meetings") }}
              </v-btn>
            </download-excel>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="6" class="text-center">
            <v-btn color="primary" dark class="ma-0" @click="resetFilter()">
              {{ $t("meeting_table.reset") }}
            </v-btn>
          </v-col>

          <v-spacer></v-spacer>
        </v-row>

        <v-toolbar flat>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">{{
                $t("meeting_table.delete")
              }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">{{
                  $t("meeting_table.cancel")
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
                  $t("meeting_table.ok")
                }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-show="editable(item.version.name, item.id)"
              small
              v-bind="attrs"
              v-on="on"
              fap
              class="ma-0 mr-md-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{ $t("meeting_table.editTooltip") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="ma-0 mr-md-2"
              v-show="editable(item.version.name, item.id)"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $t("meeting_table.deletTooltip") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small @click="copyItem(item)">
              mdi-content-paste
            </v-icon>
          </template>
          <span>{{ $t("meeting_table.copyTooltip") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>




<script>
import axios from "axios";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import { mapGetters } from "vuex";
import { VueOfflineStorage } from "vue-offline";

Vue.component("downloadExcel", JsonExcel);
export default {
  data: () => ({
    id_token: "",
    json_fields: {
      ID: "uid",
      Projekt: "project.name",
      "Téma stretnutia": "topic",
      "Názov OVM": "ovm.name",
      "Dátum stretnutia": "date",
      Spracoval: "processedBy.name",
      Schvaľovateľ: "approvedBy.name",
      Verzia: "version.name",
      "Čas začiatku": "timeBegin",
      "Čas skončenia": "timeEnd",
      "Miesto konania": "place",
      Agenda: "agenda",
      Účastníci: {
        callback: (value) => {
          return value.persons
            .map(function (p) {
              return p.name;
            })
            .join(", ");
        },
      },
    },
    json_data: [],
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
    prepare_download: true,
    loading: false,
    actualLoading: 0,
    deletingFile: false,
    errorAxios: false,
    dialog: false,
    search: "",
    dialogDelete: false,

    persons: [],
    itemVersionObj: [],
    meetings: [],
    actual_ovm: [],
    actual_topic: [],
    actual_approved_by_person: [],
    actual_processed_by_person: [],

    menuDateBegin: false,
    menuDateEnd: false,
    dateBegin: null,
    dateEnd: null,

    admin: false,
    editMeetings: [],
    editTasks: [],

    itemParticipant: [],
    itemVersion: [],
    itemTopic: [],
    itemOvm: [],
    itemWhoApproved: [],
    itemWhoProcessed: [],
    itemProjectObj: [],

    selectUID: "",
    itemUID: [],
    selectProject: "",
    itemProject: [],

    nonEditableVersion: "",
    selectParticipant: "",
    selectVersion: "",
    selectTopic: "",
    selectOvm: "",
    selectWhoApproved: "",
    selectWhoProcessed: "",

    editedIndex: -1,
    editedItem: {
      id: 0,
      uid: "",
      topic: "",
      ovmName: "",
      processedByName: "",
      approvedByName: "",
      date: "",
      version: {
        id: 0,
        name: "",
      },
      persons: [],
    },
    defaultItem: {
      id: 0,
      uid: "",
      topic: "",
      ovmName: "",
      processedByName: "",
      approvedByName: "",
      date: "",
      version: {
        id: 0,
        name: "",
      },
      persons: [],
    },
  }),

  computed: {
    ...mapGetters(["isLoggedIn", "getToken"]),
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          value: "uid",
          filter: (value) => {
            let acronyms = [""];
            let helpValue = value.replace(/[0-9]/g, "");
            if (this.selectProject != "" && this.selectProject != undefined) {
              acronyms = [
                ...new Set(
                  this.meetings
                    .filter((p) => p.project.name.includes(this.selectProject))
                    .map(function (item) {
                      return item["project"]["acronym"];
                    })
                ),
              ];
              if (acronyms.length == 0) return false;
            }
            let isProject = true;
            if (acronyms[0] != "") {
              isProject = acronyms.includes(helpValue);
            }
            if (!this.selectUID) return isProject;
            if (this.selectUID.length == 0) return isProject;
            return this.selectUID.includes(value) & isProject;
          },
        },
        {
          text: "Téma stretnutia",
          align: "start",
          sortable: false,
          value: "topic",
        },
        {
          text: "OVM",
          value: "ovmName",
          filter: (value) => {
            if (!this.selectOvm) return true;
            if (this.selectOvm.length == 0) return true;
            return this.selectOvm.includes(value);
          },
        },
        {
          text: "Schvaľovateľ",
          value: "approvedByName",
          filter: (value) => {
            if (!this.selectWhoApproved) return true;
            if (this.selectWhoApproved.length == 0) return true;
            return this.selectWhoApproved.includes(value);
          },
        },
        {
          text: "Spracovateľ",
          value: "processedByName",
          filter: (value) => {
            if (!this.selectWhoProcessed) return true;
            if (this.selectWhoProcessed.length == 0) return true;
            return this.selectWhoProcessed.includes(value);
          },
        },
        {
          text: "Účastníci",
          value: "participants",
          align: " d-none",
          filter: (value) => {
            if (!this.selectParticipant) return true;
            if (this.selectParticipant.length == 0) return true;
            var isExist = 0;
            let countPerson = this.selectParticipant.length;
            this.selectParticipant.forEach(function (word) {
              isExist = isExist + value.includes(word);
            });
            return isExist === countPerson;
          },
        },
        {
          text: "Verzia",
          value: "version.name",
          filter: (value) => {
            if (!this.selectVersion) return true;
            if (this.selectVersion.length == 0) return true;
            return this.selectVersion.includes(value);
          },
        },
        {
          text: "Dátum",
          value: "date",
          filter: (value) => {
            let parseEnd = null;
            let parseBegin = null;
            if (this.dateBegin) {
              parseBegin = parseInt(this.dateBegin.replaceAll("-", ""));
            }
            if (this.dateEnd) {
              parseEnd = parseInt(this.dateEnd.replaceAll("-", ""));
            }
            let parseValue = parseInt(value.replaceAll("-", ""));
            if (!parseBegin && !parseEnd) {
              return true;
            } else if (!parseBegin && parseEnd >= parseValue) {
              return true;
            } else if (!parseEnd && parseBegin <= parseValue) {
              return true;
            } else if (parseEnd >= parseValue && parseBegin <= parseValue) {
              return true;
            } else {
              return false;
            }
          },
        },
        { text: "Akcie", value: "actions", sortable: false },
      ];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {
    this.actualLoading = 0;
    this.loadData();
  },
  methods: {
    async downloadAllData() {
      this.setLoadingBlock();
      return axios
        .get(
          process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_ALL_MEETING,
          { headers: { Authorization: `Bearer ${this.id_token}` } }
        )
        .then((response) => {
          let allmeetings = response.data;

          allmeetings.forEach(
            (element) =>
              (element.participants = [
                ...new Set(
                  element.persons.map(
                    (item) =>
                      item.name + ", " + item.position + ", " + item.ovm.name
                  )
                ),
              ].join(", "))
          );

          allmeetings.forEach(
            (element) => (element.uid = element.project.acronym + element.id)
          );

          allmeetings.map((item) => {
            item.date = item.date.substring(0, 10);
          });

          this.json_data = allmeetings;
          this.prepare_download = false;
          this.completeLoadingBlock();
          return this.json_data;
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },

    setLoadingBlock() {
      this.actualLoading = this.actualLoading + 1;
      this.loading = true;
    },
    completeLoadingBlock() {
      this.actualLoading = this.actualLoading - 1;
      if (this.actualLoading == 0) {
        this.loading = false;
      }
    },
    async loadData() {
      this.id_token = this.getToken;
      this.actualLoading = 0;
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();

      await this.loadEditingApproval();
      await Promise.all([
        this.loadPersons(),
        this.loadProject(),
        this.loadVersion(),
      ]).then((values) => {
        this.initialize();
      });

      // await this.loadPersons();
      // await this.loadProject();
      // await this.loadVersion();
      // await this.initialize();
    },
    saveOfflineMode(key, value) {
      if (window.matchMedia("(display-mode: standalone)").matches) {
        VueOfflineStorage.set(key, value);
      }
    },
    editable(name, id) {
      if (this.admin == true) return true;
      return (
        name != process.env.VUE_APP_NONE_EDITABLE_VERSION &&
        this.editMeetings.includes(id)
      );
    },

    axiosCatchPart(e) {
      if (e.name != "TypeError") {
        this.errorAxios = true;
      }
      if (e.response.status == 403) this.$router.push("login").catch(() => {});
      this.completeLoadingBlock();
    },

    loadEditingApproval() {
      return axios
        .get(
          process.env.VUE_APP_URL +
            process.env.VUE_APP_URL_GET_EDITING_APPROVAL,
          { headers: { Authorization: `Bearer ${this.id_token}` } }
        )
        .then((response) => {
          this.admin = response.data.admin;
          this.editMeetings = response.data.meetings;
          this.editTasks = response.data.tasks;
          this.errorAxios = false;
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },

    loadVersion() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_VERSION, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemVersionObj = response.data;
          this.itemVersion = [
            ...new Set(this.itemVersionObj.map((item) => item.name)),
          ];
          this.saveOfflineMode("versions", response.data);
          this.errorAxios = false;
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadProject() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_PROJECT, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemProjectObj = response.data;

          this.itemProject = [
            ...new Set(this.itemProjectObj.map((item) => item.name)),
          ];
          this.saveOfflineMode("projects", response.data);
          this.errorAxios = false;
          this.itemProject.sort();
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadPersons() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_PERSON, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.persons = response.data;
          this.itemParticipant = this.persons.map(function (p) {
            return p.name + ", " + p.position + ", " + p.ovm.name;
          });
          this.saveOfflineMode("persons", response.data);
          this.errorAxios = false;
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },

    newMeeting() {
      this.$router.push("create");
    },
    resetFilter() {
      this.dateEnd = null;
      this.dateBegin = null;
      this.search = "";
      this.selectWhoApproved = "";
      this.selectOvm = "";
      this.selectWhoProcessed = "";
      this.selectVersion = "";
      this.selectParticipant = "";
      this.selectUID = "";
      this.selectProject = "";
    },
    setupFilters() {
      this.itemTopic = [...new Set(this.meetings.map((item) => item.topic))];
      this.itemOvm = [...new Set(this.meetings.map((item) => item.ovmName))];
      this.itemUID = [...new Set(this.meetings.map((item) => item.uid))];
      this.itemWhoApproved = [
        ...new Set(this.meetings.map((item) => item.approvedByName)),
      ];
      this.itemWhoProcessed = [
        ...new Set(this.meetings.map((item) => item.processedByName)),
      ];
    },
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toString().toLocaleUpperCase()) !== -1
      );
    },
    initialize() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_MEETING, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.nonEditableVersion = process.env.VUE_APP_NONE_EDITABLE_VERSION;
          this.meetings = response.data;

          this.meetings.forEach(
            (element) =>
              (element.participants = [
                ...new Set(
                  element.persons.map(
                    (item) =>
                      item.name + ", " + item.position + ", " + item.ovm.name
                  )
                ),
              ].join(", "))
          );

          this.meetings.forEach(
            (element) => (element.uid = element.project.acronym + element.id)
          );
          this.meetings.map((item) => {
            item.date = item.date.substring(0, 10);
          });
          this.setupFilters();
          this.errorAxios = false;
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },

    editItem(item) {
      this.$router.push("edit/" + item.id);
    },
    copyItem(item) {
      this.$router.push("copy/" + item.id);
    },
    deleteItem(item) {
      this.deletingFile = true;
      this.editedIndex = this.meetings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      axios
        .delete(
          process.env.VUE_APP_URL +
            process.env.VUE_APP_URL_GET_MEETING +
            "/" +
            this.meetings[this.editedIndex].id,
          { headers: { Authorization: `Bearer ${this.id_token}` } }
        )
        .then((response) => {
          this.meetings.splice(this.editedIndex, 1);
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    clickTableRow(index) {
      if (this.deletingFile != true) {
        this.$router.push("show/" + index.id);
      }
      this.deletingFile = false;
    },
  },
};
</script>


<style scoped>
.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
</style>