<template>
  <div>
    <NavBar />
    <table-of-meetings />
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import TableOfMeetings from "../components/TableOfMeetings";

export default {
  name: "MeetingsTable",

  components: {
    NavBar,
    TableOfMeetings,
  },
};
</script>
